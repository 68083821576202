import { styled } from '@mentimeter/ragnar-styled';
import type { Size } from '@mentimeter/ragnar-dsc';
import { addUnit } from '@mentimeter/ragnar-utils';
import type { Rule, Responsive } from '@mentimeter/ragnar-styled';
import React, { forwardRef } from 'react';
import { inputRule, type InputRuleStatus } from '../utils/inputRule';

const rule: Rule<{
  minHeight?: string | undefined;
  maxHeight?: Responsive<Size> | undefined;
  inputSize?: 'default' | 'compact' | undefined;
  status?: InputRuleStatus | undefined;
}> = ({ minHeight, theme, maxHeight, inputSize = 'default', status }) => {
  const padding = {
    default: {
      padding: addUnit(theme.kosmosSpacing.space4),
    },
    compact: {
      paddingTop: addUnit(theme.kosmosSpacing.space3),
      paddingBottom: addUnit(theme.kosmosSpacing.space3),
      paddingLeft: addUnit(theme.kosmosSpacing.space2),
      paddingRight: addUnit(theme.kosmosSpacing.space2),
    },
  };

  const borderRadius = {
    default: {
      borderRadius: addUnit(theme.kosmosBorderRadius.xl),
    },
    compact: {
      borderRadius: addUnit(theme.kosmosBorderRadius.lg),
    },
  };

  const fontSize = {
    default: {
      fontSize: addUnit(theme.kosmosFontSize[100]),
      lineHeight: addUnit(theme.kosmosLineHeight.fixed[125]),
    },
    compact: {
      fontSize: addUnit(theme.kosmosFontSize['87.5']),
      lineHeight: addUnit(theme.kosmosLineHeight.fixed[100]),
    },
  };

  return {
    ...inputRule({ theme, status }),
    ...(padding[inputSize] || padding.default),
    ...(borderRadius[inputSize] || borderRadius.default),
    ...(fontSize[inputSize] || fontSize.default),
    minHeight: minHeight !== undefined ? minHeight : '70px',
    resize: 'vertical',
    maxHeight,
  };
};

const TextareaItemStyled = styled('textarea')(rule, 'kosmosSpacing');

type TextareaItemProps = React.ComponentProps<typeof TextareaItemStyled>;

const TextareaItem = forwardRef<HTMLTextAreaElement | null, TextareaItemProps>(
  (props, ref) => (
    <TextareaItemStyled
      // Reset selector
      className="r-textarea"
      {...props}
      ref={ref}
    />
  ),
);

export type TextareaItemT = React.ComponentProps<typeof TextareaItem>;
export { TextareaItem };
